import React from "react"
import { connect, ConnectedProps } from "react-redux"

import { store } from "@/types/interface"
import { getKidsTypes } from "../actions"
import { Layout } from "../components"
import { SEO } from "../components/common"
import { CourseList } from "../components/kids"
import styles from "./kids.module.css"

const connector = connect(
  // mapStateToProps
  ({ courseType }: { courseType: store.CourseType }) => ({
    courseTypes: courseType.courseTypes,
  }),
  // mapDispatchToProps
  { getKidsTypes }
)

type Props = ConnectedProps<typeof connector>

const Kids: React.FC<Props> = ({ courseTypes, getKidsTypes }) => {
  React.useEffect(() => {
    document &&
      document.documentElement.style.setProperty("--color-primary", "#FF9900")
    getKidsTypes()

    return () =>
      document.documentElement.style.setProperty("--color-primary", "#16424D")
  }, [])

  return (
    <Layout>
      <SEO title="Culture House キッズ講座" />
      <div className="container">
        <h1 className={styles.title}>キッズ</h1>
        <CourseList courseTypes={courseTypes} colors={colors}/>
      </div>
    </Layout>
  )
}

const colors = ["red", "orange", "green", "#40E0D0", "blue", "#1D2088", "purple"]

export default connector(Kids)

import React from "react"
import { connect, ConnectedProps } from "react-redux"

import { store } from "@/types/interface"
import { CourseCard } from "../common"
import { filterCoursesByMultiTypes } from "../../actions"
import styles from "./course-list.module.css"

const connector = connect(
  // mapStateToProps
  ({ course }: { course: store.Course }) => ({
    courses: course.courseAbstracts,
  }),
  // mapDispatchToProps
  { filterCoursesByMultiTypes }
)

type Props = {
  colors: string[]
  courseTypes: store.CourseType["courseTypes"]
} & ConnectedProps<typeof connector>

const CourseList: React.FC<Props> = props => {
  React.useEffect(() => {
    let courseTypeIds = props.courseTypes.map(courseType => courseType._id)
    props.filterCoursesByMultiTypes(courseTypeIds)
  }, [props.courseTypes])

  return (
    <>
      {props.courseTypes.map((courseType, i) => {
        return (
          <section key={courseType._id} className={styles.courseList}>
            <h2
              className={styles.subTitle}
              style={{ background: props.colors[i], }}
            >
              {courseType.name}
            </h2>
            {props.courses
              .filter(course => course.courseTypeId === courseType._id)
              .map(course => {
                return (
                  <CourseCard
                    key={course._id}
                    course={course}
                    color={props.colors[i]}
                  />
                )
              })}
          </section>
        )
      })}
    </>
  )
}

export default connector(CourseList)
